<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
        :title="$t('page.share.title')"
        @edit="$emit('edit')"
    >
      <p class="has-text-justified">{{ $t('page.share.description') }}</p>
    </page-title>
    <div
        v-for="(shareholder, index) in filteredModel"
        :key="index"
        class="box"
    >
      <h3 class="is-size-4">
        {{ shareholder.individual ? `${shareholder.firstname} ${shareholder.lastname}` : shareholder.corporationname }}
      </h3>
      <p></p>
      <b-field v-if="shareholder.isVotingShares" :label="isViewModeCreate ? $t('page.shareholder.votingShares') + ` : ${shareholder.votingSharesPercentage}%` : undefined">
        <b-slider
            v-if="isFieldEditable(`$.shareholder[${index}].votingSharesPercentage`)"
            v-model="shareholder.votingSharesPercentage"
            :min="0"
            :max="100">
          <b-slider-tick :value="33">1/3</b-slider-tick>
          <b-slider-tick :value="50">1/2</b-slider-tick>
          <b-slider-tick :value="66">2/3</b-slider-tick>
        </b-slider>
        <b-tag
            v-else
            type="is-blue is-outlined"
            size="is-medium"
        >
          {{ $t('page.shareholder.votingShares') }} : {{ shareholder.votingSharesPercentage }}%
        </b-tag>
      </b-field>
      <b-field v-if="shareholder.isParticipatingShares" :label="isViewModeCreate ? $t('page.shareholder.participatingShares') + ` : ${shareholder.participatingSharesPercentage}%` : undefined">
        <b-slider
            v-if="isFieldEditable(`$.shareholder[${index}].participatingSharesPercentage`)"
            v-model="shareholder.participatingSharesPercentage"
            :min="0"
            :max="100">
          <b-slider-tick :value="33">1/3</b-slider-tick>
          <b-slider-tick :value="50">1/2</b-slider-tick>
          <b-slider-tick :value="66">2/3</b-slider-tick>
        </b-slider>
        <b-tag
            v-else
            type="is-blue is-outlined"
            size="is-medium"
        >
          {{ $t('page.shareholder.participatingShares') }} : {{ shareholder.participatingSharesPercentage }}%
        </b-tag>
      </b-field>
    </div>
    <b-message
        v-if="isVotingSharesNotOneHundred"
        type="is-danger"
    >
      {{ $t('page.share.votingSharesNotOneHundred') }}
    </b-message>
    <b-message
        v-if="isParticipatingSharesNotOneHundred"
        type="is-danger"
    >
      {{ $t('page.share.participatingSharesNotOneHundred') }}
    </b-message>
  </fieldset>
</template>

<script>
  import page from '@/mixins/page'
  import cloneDeep from 'lodash-es/cloneDeep'

  export default {
    name: 'PageShare',
    mixins: [
      page('shareholder') // Share uses data from Shareholder
    ],
    computed: {
      isVotingSharesNotOneHundred () {
        return this.errors.includes('votingSharesNotOneHundred')
      },
      isParticipatingSharesNotOneHundred () {
        return this.errors.includes('participatingSharesNotOneHundred')
      },
      filteredModel () {
        return this.model.filter(shareholder => shareholder.isVotingShares || shareholder.isParticipatingShares)
      }
    },
    methods: {
      updateModelPartially () {
        const storedModelCopy = cloneDeep(this.storedModel['shareholder'])
        this.model.forEach((director, index) => {
          ['votingSharesPercentage', 'participatingSharesPercentage'].forEach((prop) => {
            storedModelCopy[index][prop] = director[prop]
          })
        })
        this.model = storedModelCopy
      },
      validate () {
        this.validateNotOneHundred()
      },
      validateNotOneHundred () {
        const votingSharesSum = this.model
          .reduce((a, b) => a + (b.votingSharesPercentage || 0), 0)
        const votingSharesHaveThirds = this.model
          .filter(s => [33, 66].includes(s.votingSharesPercentage))
          .length >= 1

        if ((!votingSharesHaveThirds && votingSharesSum !== 100) || (votingSharesHaveThirds && ![99, 100].includes(votingSharesSum)))
          this.addError('votingSharesNotOneHundred')
        else
          this.removeError('votingSharesNotOneHundred')

        const participatingSharesSum = this.model
          .reduce((a, b) => a + (b.participatingSharesPercentage || 0), 0)
        const participatingSharesHaveThirds = this.model
          .filter(s => [33, 66].includes(s.participatingSharesPercentage))
          .length >= 1

        if ((!participatingSharesHaveThirds && participatingSharesSum !== 100) || (participatingSharesHaveThirds && ![99, 100].includes(participatingSharesSum)))
          this.addError('participatingSharesNotOneHundred')
        else
          this.removeError('participatingSharesNotOneHundred')
      }
    }
  }
</script>
